.footer-list-item {
  padding: 0;
}

.footer-menu-title {
  font-size: 1rem;
  text-transform: uppercase;
}

.footer-logo {
  transform: rotate(-5deg);
  margin-bottom: 30px;
  position: relative;
  bottom: 60px;
  @media (max-width: 375px) {
    bottom: 0;
  }
}

.footer-social-media-icons {
  margin: 20px 0;
}

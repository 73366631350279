.login-form-container {
  min-height: 100vh;
  @media (max-width: 375px) {
    padding-top: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    min-height: 10vh;
  }
}

.login-form {
  padding-top: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.07);
  @media (max-width: 375px) {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
